.App {
  text-align: center;
}

.Nav {
  display: flex;
}

.Nav__list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.Nav__item {
  padding: 0 10px;
}

.Nav__item--last {
  margin-left: auto;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
